<template>
  <v-container fluid>
    <div v-if="loadingAnualReport">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="4"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                {{ statusLoading }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else-if="leadsMktReportIsEmpty">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center"> </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>
                No se encontraron Leads.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else>
      <div class="py-5"></div>
      <v-card elevation="0">
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-row justify="center" align="center">
              <v-col class="px-10" cols="12" sm="6">
                <v-row justify="center" align="center">
                  <FilterAutocomplete
                    v-if="zonesList.length > 0"
                    v-model="filter"
                    :items="zonesList"
                    label="Filtrar por Desarrollo"
                  />
                </v-row>
              </v-col>
              <v-col class="px-7" cols="12" sm="6">
                <FilterAutocomplete
                  v-model="howDidFilter"
                  :items="sourcesList"
                  label="Filtrar por Fuente"
                  itemText="name"
                  itemValue="_id"
                />
              </v-col>
            </v-row>
            <v-col cols="12" sm="2">
              <v-btn elevation="0" color="primary" @click="clearAll()">
                Limpiar filtros
              </v-btn>
            </v-col>
          </v-col>
          <!-- New data table -->
          <v-col cols="12">
            <v-card elevation="0" class="my-10" flat>
              <v-data-table
                hide-default-footer
                class="elevation-1"
                :headers="dataTableInfoAll.headers"
                :items="dataTableInfoAll.desserts"
                :items-per-page="100"
              >
                <template v-slot:body="props">
                  <tr
                    v-for="(row, r) in props.items"
                    :key="r"
                    :class="`${row.color} ${row.txtcolor}`"
                  >
                    <td
                      class="pa-2"
                      v-for="(column, c) in props.headers"
                      :key="c"
                    >
                      <div v-if="c === 0">
                        {{ row.name }}
                      </div>
                      <div v-else-if="column.text !== 'Total'">
                        <div
                          v-for="(data, i) in getTotalByValueNewv2(
                            row.get,
                            column.value,
                            row.phase
                          )"
                          :key="i"
                        >
                          <div v-if="row.get === '='">
                            <span
                              v-if="row.phase === 'all'"
                              :class="data.total === 0 ? 'grey--text' : ''"
                            >
                              {{ data.total }}
                            </span>
                            <span
                              v-else
                              :class="
                                data.total === 0 ? 'grey--text' : 'total-info'
                              "
                              @click="
                                data.total === 0
                                  ? () => {}
                                  : getLeads({
                                      year: column.value,

                                      phase: row.phase
                                    })
                              "
                            >
                              {{ data.total }}
                              <small> ({{ data.avg }}%)</small>
                            </span>
                          </div>
                          <div v-else>
                            <div>${{ getThreeNumbers(data.mxm) }} MXN</div>
                            <div>${{ getThreeNumbers(data.usd) }} USD</div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="(data, i) in getTotalAllNewv2(
                            row.get,
                            row.phase
                          )"
                          :key="i"
                        >
                          <div v-if="row.get === '='">
                            <span
                              v-if="row.phase === 'all'"
                              :class="data.total === 0 ? 'grey--text' : ''"
                            >
                              {{ data.total }}
                            </span>
                            <span
                              v-else
                              :class="data.total === 0 ? 'grey--text' : ''"
                            >
                              {{ data.total }}
                              <small> ({{ data.avg }}%)</small>
                            </span>
                          </div>
                          <div v-else>
                            <div>${{ getThreeNumbers(data.mxm) }} MXN</div>
                            <div>${{ getThreeNumbers(data.usd) }} USD</div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <!---->
          <!---->
          <!---->
          <!-- End personal data table-->
          <!---->
          <!---->
          <!---->
        </v-row>
      </v-card>
    </div>
    <ListLeadsByReportDetail
      :leads="leads"
      :dialog="dialog"
      :loading="loadingLeadList"
      @setDialog="value => (dialog = value)"
    >
    </ListLeadsByReportDetail>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListLeadsByReportDetail from "./component/ListLeadsByReportDetail";
import FilterAutocomplete from "./component/FilterAutocomplete.vue";

export default {
  components: {
    ListLeadsByReportDetail,
    FilterAutocomplete
  },
  data() {
    return {
      leads: [],
      dialog: false,
      loadingLeadList: false,
      statusLoading: "Obteniendo Leads ...",
      loadingAnualReport: true,
      leadsToGetAnualReport: null,
      leadstTmpToGetAnualReport: null,
      //
      leadsMktReportIsEmpty: false,
      //
      realEstateList: [],
      filter: [],
      howDidFilter: [],
      tab: null,
      //
      dataTableInfoAll: {},
      //
      clearFiltersAllOn: false,
      //
      // Lista de como nos contacto
      sourcesList: [],
      //
      years: [],
      headers: [],
      desserts: [
        {
          name: "Lead Reales",
          get: "=",
          phase: "all",
          class: "mx-2",
          color: "grey lighten-4",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "Descartados",
          get: "=",
          phase: "discarded",
          class: "mx-2 ",
          color: "grey lighten-2",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "$ Descartado",
          get: "$",
          phase: "discarded",
          class: "mx-2 ",
          color: "grey lighten-2",
          txtcolor: "grey--text text--darken-4"
        },
        {
          name: "Activos",
          get: "=",
          phase: "active",
          class: "mx-2 ",
          color: "purple lighten-5",
          txtcolor: "deep-purple--text text--darken-4"
        },
        {
          name: "Oportunidad",
          get: "$",
          phase: "active",
          class: "mx-2",
          color: "purple lighten-5",
          txtcolor: "deep-purple--text text--darken-4"
        },
        {
          name: "Operaciones",
          get: "=",
          phase: "in-operation",
          class: "mx-2 ",
          color: "blue lighten-5",
          txtcolor: "blue--text text--darken-4"
        },
        {
          name: "Posibilidad de ingreso",
          get: "$",
          phase: "in-operation",
          class: "mx-2 ",
          color: "blue lighten-5",
          txtcolor: "blue--text text--darken-4"
        },
        {
          name: "Cerradas",
          get: "=",
          phase: "finished",
          class: "mx-2 ",
          color: "yellow lighten-5",
          txtcolor: "brown--text text--darken-4"
        },
        {
          name: "Ventas",
          get: "$",
          phase: "finished",
          class: "mx-2",
          color: "yellow lighten-5",
          txtcolor: "brown--text text--darken-4"
        }
      ],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ]
    };
  },
  computed: {
    ...mapState({
      filters: state => state.leads.filters,
      leadListByAnualMKTYear: state => state.reports.leadListByAnualMKTYear,
      leadsList: state => state.reports.leadListByComertialReport
    }),
    zonesList() {
      return this.filters.zones;
    }
  },
  watch: {
    filter() {
      if (this.clearFiltersAllOn) return;
      this.getAnualMktReportWithFilters();
    },
    howDidFilter() {
      if (this.clearFiltersAllOn) return;
      this.getAnualMktReportWithFilters();
    }
  },
  async mounted() {
    this.loadingAnualReport = true;
    await this.fetchListLeadsInAnualMKT({ groupBy: "year" });
    const years = Object.keys(this.leadListByAnualMKTYear);
    if (years.length === 0) {
      this.leadsMktReportIsEmpty = true;
    }
    this.setDataTableInfoAll();
    // Traemos a todos los sources del vuex
    this.getTrafficData();
    this.loadingAnualReport = false;
  },
  methods: {
    ...mapActions({
      fecthReportList: "reports/fecthReportList",
      createReportCreateColumn: "reports/createReportCreateColumn",
      updateReportUpdateColumn: "reports/updateReportUpdateColumn",
      fetchListLeadsInAnualMKT: "reports/fetchListLeadsInAnualMKT",
      getListLeads: "reports/fetchListLeadsByDetailReport"
    }),
    //
    async getLeads(data) {
      this.loadingLeadList = true;
      this.dialog = true;
      await this.getListLeads({
        year: data.year,
        phase: data.phase,
        ...this.handlerFilters(),
        typeReport: "anualMkt"
      });
      this.leads = this.leadsList;
      this.loadingLeadList = false;
    },
    fillSourceList() {
      for (let i = 0; i < this.filters.sources.length; i++) {
        this.sourcesList.push(this.filters.sources[i].source);
      }
    },
    //
    getTrafficData() {
      //Si this.filters no existe o es null no hace nada
      if (!this.filters) return;
      //Si hay sources llama a la funcion para llenar la lista
      if (this.filters.sources) this.fillSourceList();
    },
    //
    getThreeNumbers(number) {
      if (number === undefined || number === 0) {
        return 0;
      } else {
        const a = number.toString();

        const res = a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return res;
      }
    },
    //
    yearsAvailableInAnualMKTMethod() {
      // Esto retornara los años que se encuentren la respuesta
      // e. ['2023', '2024']
      this.setDataTableInfoAll();
      // El año a visualizar siempre sera el actual
      // debido a que la repuesta devuelve como ultimo la fecha actual
    },
    //
    setDataTableInfoAll() {
      const desserts = this.desserts;
      const dataAnualMKT = this.leadListByAnualMKTYear;
      const headers = this.getHeadersNew(dataAnualMKT);
      this.dataTableInfoAll = {
        headers,
        desserts
      };
    },
    //
    getHeadersNew(years) {
      const headers = [];
      // Se le inserta la primer columna del año
      headers.push({
        text: "Años",
        value: "name",
        class: "ma-0 px-2 py-8 white--text blue-grey darken-4",
        icon: "",
        sortable: false
      });
      for (const [yearNumber] of Object.entries(years)) {
        // Se le inserta la columna de los meses
        headers.push({
          text: yearNumber,
          value: yearNumber,
          class: "ma-0 px-2 white--text blue-grey darken-4",
          icon: "",
          sortable: false
        });
      }
      //Se le inserta la ultima columna del total
      headers.push({
        text: "Total",
        value: "total",
        class: "ma-0 px-2 white--text blue-grey darken-4",
        icon: "",
        sortable: false
      });
      return headers;
    },
    //
    getTotalAmountNew(year) {
      let total = 0;
      let totalbudgetMXN = 0;
      let totalbudgetUSD = 0;
      for (const key in year) {
        total = total + year[key].total;
        totalbudgetMXN = totalbudgetMXN + year[key].budgetMXN;
        totalbudgetUSD = totalbudgetUSD + year[key].budgetUSD;
      }
      return { total, totalbudgetMXN, totalbudgetUSD };
    },
    //
    getTotalByValueNewv2(get, year, phase) {
      // rowName -> Lead Reales, Descartados, Activos
      // get -> =, $
      // year -> 2024, 2023
      // month -> Enero, Febrero, Marzo, Abril
      // phase -> all, discarded, active, in-operation, finished
      const tableCurrentByYear = this.leadListByAnualMKTYear[year];
      if (tableCurrentByYear === undefined)
        return [
          {
            total: 0,
            avg: 0,
            mxm: 0,
            usd: 0
          }
        ];
      const { total, totalbudgetMXN, totalbudgetUSD } = this.getTotalAmountNew(
        tableCurrentByYear
      );
      if (phase === "all") {
        return [
          {
            total: total,
            avg: 100,
            mxm: totalbudgetMXN,
            usd: totalbudgetUSD
          }
        ];
      } else {
        const yearWithPhase = tableCurrentByYear[phase];
        if (yearWithPhase != undefined) {
          return [
            {
              total: yearWithPhase.total,
              avg: ((yearWithPhase.total * 100) / total).toFixed(1),
              mxm: yearWithPhase.budgetMXN,
              usd: yearWithPhase.budgetUSD
            }
          ];
        } else {
          return [
            {
              total: 0,
              avg: 0,
              mxm: 0,
              usd: 0
            }
          ];
        }
      }
    },
    getTotalAllNewv2(get, phase) {
      const tableCurrentAllYears = this.leadListByAnualMKTYear;
      const arrayYears = Object.keys(tableCurrentAllYears);
      if (arrayYears.length === 0) {
        return [
          {
            total: 0,
            avg: 0,
            mxm: 0,
            usd: 0
          }
        ];
      }
      let totalAll = 0;
      let totalbudgetMXNAll = 0;
      let totalbudgetUSDAll = 0;
      let totalInYears = 0;
      let totalInYearsMXM = 0;
      let totalInYearsUSD = 0;
      for (const year in tableCurrentAllYears) {
        const {
          total,
          totalbudgetMXN,
          totalbudgetUSD
        } = this.getTotalAmountNew(tableCurrentAllYears[year]);
        totalAll = totalAll + total;
        totalbudgetMXNAll = totalbudgetMXNAll + totalbudgetMXN;
        totalbudgetUSDAll = totalbudgetUSDAll + totalbudgetUSD;
      }
      if (phase === "all") {
        return [
          {
            total: totalAll,
            avg: 100,
            mxm: totalbudgetMXNAll,
            usd: totalbudgetUSDAll
          }
        ];
      } else {
        for (const year in tableCurrentAllYears) {
          const yearWithout = tableCurrentAllYears[year];
          const yearWithPhase = yearWithout[phase];
          if (yearWithPhase != undefined) {
            totalInYears = totalInYears + yearWithPhase.total;
            totalInYearsMXM = totalInYearsMXM + yearWithPhase.budgetMXN;
            totalInYearsUSD = totalInYearsUSD + yearWithPhase.budgetUSD;
          }
        }
        return [
          {
            total: totalInYears,
            avg: ((totalInYears * 100) / totalAll).toFixed(1),
            mxm: totalInYearsMXM,
            usd: totalInYearsUSD
          }
        ];
      }
    },
    //
    async clearAll() {
      if (this.filter.length === 0 && this.howDidFilter.length === 0) return;
      this.clearFiltersAllOn = true;
      this.filter = [];
      this.howDidFilter = [];
      await this.getAnualMktReportWithFilters();
      this.clearFiltersAllOn = false;
    },
    isnullOrEmpty(data) {
      if (data === null || data === undefined || data === "") {
        return true;
      } else {
        return false;
      }
    },
    async getAnualMktReportWithFilters() {
      await this.fetchListLeadsInAnualMKT(this.handlerFilters());
      // eliminar
      this.yearsAvailableInAnualMKTMethod();
    },
    handlerFilters() {
      const localZone = this.filter;
      const localSources = this.howDidFilter;
      const filtersMkt = {};
      filtersMkt.groupBy = "year";
      if (localZone.length > 0) {
        filtersMkt.zones = localZone;
      }
      if (localSources.length > 0) {
        filtersMkt.sources = localSources;
      }
      return filtersMkt;
    }
  }
};
</script>
<style scoped>
.v-card-tabs {
  padding: 10px;
  background-color: #ffffff !important;
  border: 2px solid rgb(27, 26, 26) !important;
}
</style>
